.widget {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 130px;
  border-radius: 15px;
  border: 1px solid rgba(0,0,0,.133);
  background-color: #fff;
  box-shadow: 0 1px 4px -3px #000;
}

.logo {
  width: 65%;
  height: 30px;
  padding: 0 !important;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.leaves {
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 9;
}

.leaf-icon,
.leaf_item{
  color: #fff;
  height: 30px;
  width: 40px;
  margin-right: 4px;
  cursor: pointer;
}


.react-tooltip {
  z-index: 999;
}

.leaf_tooltip .tooltip-inner {
  font-size: 20px;
}

.leaf_tooltip {
  cursor: pointer;
  z-index: 99999;
}

.column.green_steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  width: 65%;
  z-index: 9;
}
.progressContainer {
  width: calc(100% - 25px);
  background-color: #a4ddbc;
  height: 8px;
  border-radius: 10px;
  cursor: pointer;
}
.progress {
  position: relative;
  height: 100%;
  background-color: #009448;
  border-radius: 10px;
}
.sprout {
  width: 20px;
  height: 20px;
  padding: 0 !important;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: relative;
}



.closeWidget {
  position: absolute;
  top: 0;
  right: 5px;
  font-weight: bold;
  color: #14934b;
  cursor: pointer;
  font-size: 20px;
  transform: rotate(45deg);
  z-index: 99;
}

.widget.close {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
  transition: all 0.5s ease;
  border-radius: 10px;
}

.widget.close .logo {
  width: 60%;
  height: 60%;
  cursor: pointer;
}

.widget.close .leaves,
.widget.close .green_steps{
  padding: 0;
  width: 0;
  display: none;
  transition: all 0.5s ease;
}

.widget.close .closeWidget {
  font-size: 0;
}

.widget.close .link {
  display: none;
}

.link {
  position: absolute;
  inset: 0;
}
